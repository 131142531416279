<template>
  <HeaderSearch :title="null" :placeholder="$t('search')" @searchKeyword="searchKeyword" />
  <div class="scenicspot_wrap">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model:loading="loading"
        class="list"
        :finished="finished"
        :loading-text="$t('loading_text')"
        :loosing-text="$t('loosing_text')"
        :pulling-text="$t('pulling_text')"
        :finished-text="$t('no_more')"
        @load="loadScenicSpotList"
      >
        <template v-for="(item, index) in list" :key="index">
          <div class="card" :style="`background-image: url(${item.screenshot})`"
            @click="$router.push({ name: 'TicketDetail', params: { id: item.id } })">
            <div class="info">
              <h5 class="d-flex align-items-center justify-content-between px-3 pt-3 pb-2">
                <span>{{ item.name }}</span>
                <van-icon name="arrow" />
              </h5>
              <div v-if="item.npc_list" class="d-flex align-items-center npc_list">
                <van-image v-for="(npc, index) in item.npc_list" :key="index" :src="npc.avatar" width="34px"
                  height="34px" round />
                  <span class="small text-muted px-2">{{ $t('ai_npc') }}</span>
              </div>
              <div v-else class="van-ellipsis px-3">{{ stripTags(item.scenic_spot.description) }}</div>
            </div>
          </div>
        </template>
      </van-list>
    </van-pull-refresh>
  </div>
  <van-tabbar v-model="activeTab">
    <van-tabbar-item name="home" icon="coupon-o">{{ $t('appname') }}</van-tabbar-item>
    <van-tabbar-item name="user" icon="user-o" @click="$router.push({ name: 'UserProfile' })">{{ $t('profile') }}</van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { reactive, toRefs, ref } from "vue";
// import { useRouter, useRoute } from "vue-router";
import { listForSale } from "@/api/ticket.service";
import HeaderSearch from "@/components/HeaderSearch.vue";
import Moment from "moment";
Moment.locale("zh-CN");
import { stripTags } from "@/utils/util";
// import { showLoadingToast, showToast, showConfirmDialog } from "vant";
export default {
  name: "TrendsPage",
  components: { HeaderSearch },
  setup() {
    // const router = useRouter();
    // const route = useRoute();
    const state = reactive({
      page: 1,
      page_size: 10,
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      keyword: "",
      activeTab: "home",
    });
    const hideCommentref = ref(null);

    const settingsHide = (item, index) => {
      state.list.forEach((e, i) => {
        if (i == index) {
          e.off = !item.off;
        } else {
          e.off = false;
        }
      });
    };

    const loadScenicSpotList = async () => {
      try {
        const { result } = await listForSale({
          seller_id: window.localStorage.getItem("seller_id"),
          name: state.keyword,
          page: state.page,
          page_size: state.page_size,
        });
        if (result.items.length > 0) {
          state.list.push(...result.items);
          state.page++;
        }
        if (!result.hasNext || result.items.length < state.page_size) {
          state.finished = true;
        }
        state.refreshing = false;
        state.loading = false;
      } catch (error) {
        console.log(error);
      }
    };

    const onRefresh = () => {
      state.finished = false;
      state.loading = true;
      state.list = [];
      state.page = 1;
      loadScenicSpotList();
    };

    const getDisplatTime = (time) => {
      return Moment(parseInt(time) * 1000).fromNow();
    };

    const searchKeyword = (value) => {
      state.keyword = value;
      onRefresh();
    };

    return {
      ...toRefs(state),
      settingsHide,
      loadScenicSpotList,
      onRefresh,
      getDisplatTime,
      searchKeyword,
      hideCommentref,
      stripTags,
    };
  },
};
</script>
<style lang="less" scoped>
.scenicspot_wrap {
  width: 100%;
  min-height: 100vh;
  margin-bottom: 40px;
  background-color: #fff;
  padding: 0 20px;

  .list {
    min-height: 100vh;
    padding: 0;

    .card {
      height: 200px;
      margin-bottom: 20px;
      padding: 0;
      border-radius: 10px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      overflow: hidden;

      .info {
        background-color: rgba(255, 255, 255, 0.5);
        height: 100px;
        width: 80%;
        margin-top: 80px;
        .npc_list {
          padding-left: 20px;
          .van-image {
            margin-left: -5px;
          }
        }

      }
    }
  }
}
</style>
