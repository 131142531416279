<template>
  <div class="header_search_box">
    <div class="search_box">
      <van-field
        v-model="value" :border="false"
        :class="[is_show_placeholder && !value ? '' : 'input_pd']"
        :placeholder="placeholder"
        :input-align="is_show_placeholder && !value ? 'center' : 'left'"
        @focus="is_show_placeholder = false"
        @blur="fieldBlur"
      />
      <div :class="['placeholder', is_show_placeholder && !value ? '' : 'show_left']">
        <van-icon :name="searchIcon" size="24px" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
export default {
  name: "HeaderSearch",
  props: {
    title: {
      type: String,
      require: false
    },
    placeholder: {
      type: String,
      require: false,
      default: "搜索"
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      value: "",
      is_show_placeholder: true,
    });

    const fieldBlur = () => {
      state.is_show_placeholder = true;
      emit("searchKeyword", state.value);
    };

    const searchIcon = require("@/assets/images/search.svg");

    return {
      ...toRefs(state),
      fieldBlur,
      searchIcon
    };
  },
};
</script>

<style lang="less" scoped>
.header_search_box {
  .search_box {
    position: relative;
    padding: 24px 20px;

    .van-field {
      border-radius: 20px;
      border: 1px solid #e5e5e5;
    }

    .input_pd {
      padding-left: 38px;
    }

    .placeholder {
      position: absolute;
      left: 30%;
      top: 50%;
      transform: translate(0, -50%);
      display: flex;
      align-items: center;
      transition: all 0.3s;
      background-color: transparent;

      i {
        font-size: 50px;
        color: #b3b3b3;
      }

      span {
        color: #b3b3b3;
        font-size: 28px;
      }
    }

    .show_left {
      left: 30px;
    }
  }
}
</style>
